import { of, Observable, forkJoin, from, catchError, map, tap, take } from 'rxjs'
import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { PermissionsService } from '@awork/features/workspace/services/permission-service/permissions.service'
import { AccountService } from '@awork/_shared/services/account-service/account.service'
import { fetchTranslation } from '../_shared/functions/translation'
import { HttpErrorResponse } from '@angular/common/http'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { PermissionsQuery } from '@awork/features/workspace/state/permissions.query'

@Injectable({ providedIn: 'root' })
export class FrameworkGuard {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private permissionsQuery: PermissionsQuery,
    private accountService: AccountService,
    private logService: LogService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let errorHandled = false

    const permissions$ = !this.permissionsService.permissionsFetched
      ? this.permissionsService.fetchPermissions()
      : this.permissionsQuery.selectUserPermissions()

    return forkJoin([
      permissions$.pipe(
        map(userPermissions => {
          // If the user is admin, allow everything
          if (userPermissions && userPermissions.isAdmin) {
            return true
          }

          const url = state.url // ex.: /users/overview

          // ex.: url -> users-overview
          const permission = url.substr(1).replace(/\//g, '-')

          // check if this route is NOT allowed
          if (userPermissions && !userPermissions.isAdmin && !userPermissions.isAllowedToSeeMenuItem(permission)) {
            this.router.navigate(['/not-allowed'])
            return false
          }

          return true
        }),
        take(1),
        catchError(error => {
          if (!errorHandled) {
            errorHandled = true
            return this.errorHandler(error)
          } else {
            return this.logError()
          }
        })
      ),
      this.accountService.getAccountDetails().pipe(
        tap(() => {
          return from(fetchTranslation(false)())
        }),
        map(() => true),
        take(1),
        catchError(error => {
          if (!errorHandled) {
            errorHandled = true
            return this.errorHandler(error)
          } else {
            return this.logError()
          }
        })
      )
    ]).pipe(
      map(responses => {
        return !responses.some(response => !response)
      }),
      catchError(error => {
        if (!errorHandled) {
          errorHandled = true
          return this.errorHandler(error)
        } else {
          return this.logError()
        }
      })
    )
  }

  /**
   * Handles API call errors. Logout or refreshes the session
   * @param {HttpErrorResponse} error
   * @returns {Observable<boolean>}
   */
  private errorHandler(error: HttpErrorResponse): Observable<boolean> {
    if (error.status !== 401) {
      this.accountService.logout(true, null, null, {
        message: `Framework Guard failed with error ${error.status}`,
        error
      })
    } else {
      this.accountService.refreshSession().subscribe()
    }
    return of(false)
  }

  /**
   * Logs 'error handled' to LogDNA
   * @returns {Observable<boolean>}
   */
  private logError(): Observable<boolean> {
    this.logService.sendLogDNA('WARN', 'Framework guard: Error already handled', null)

    return of(false)
  }
}
