export interface RetryOptions {
  shouldRetryResponse?: (res: Response) => boolean
  retryCount?: number
  retryDelay?: (n: number) => number
}

export interface StrictRetryOptions {
  shouldRetryResponse: (res: Response) => boolean
  retryCount: number
  retryDelay: (n: number) => number
}

export const defaultRetryOptions: StrictRetryOptions = {
  shouldRetryResponse: res => res.status >= 500, // Only retry if the server failed
  retryCount: 2, // Retry count 2 means 1 request and 2 retries so max. 3 requests in total
  retryDelay: n => Math.pow(n, 3) * 100 // Slowly increase retry delay (e.g. 100, 800, 2700)
}

export async function betterFetch(url: string, options?: RequestInit & RetryOptions): Promise<Response> {
  // To prevent a Edge bug
  if (options) {
    options.body = undefined
  }

  const strictOptions = Object.assign(defaultRetryOptions, options)
  for (let i = 0; i < strictOptions.retryCount + 1; i++) {
    try {
      const res = await window.fetch(url, options)
      const shouldRetry = strictOptions.shouldRetryResponse(res)
      if (shouldRetry) {
        throw new Error(`Retrying request to '${res.url}' with status '${res.status}' (#${i + 1})`)
      } else {
        return res
      }
    } catch (err) {
      const wasLastAttempt = i === strictOptions.retryCount
      if (wasLastAttempt) {
        throw err
      } else {
        const delay = strictOptions.retryDelay(i + 1)
        await new Promise(resolve => setTimeout(resolve, delay))
      }
    }
  }
  throw new Error('the loop above is exhaustive, so this should should never happen')
}
