import { assetsUrl, environment, version } from '@awork/environments/environment'
import { Language, LanguageObj, setLanguage } from '@awork/_shared/functions/get-language'
import getLanguage from '@awork/_shared/functions/get-language'
import isTest from '@awork/_shared/functions/is-test'
import { betterFetch } from '@awork/_shared/functions/better-fetch'
import { ISOLanguage } from '@awork/_shared/models/account.model'

/**
 * Fetches the translation and assign it to the `q` global variable
 */
export function fetchTranslation(set = true): () => Promise<void> {
  return async () => {
    let languageObj: LanguageObj

    if (set) {
      languageObj = await setLanguage()
    } else {
      languageObj = {
        language: getLanguage() as Language,
        iso: getLanguage(true) as ISOLanguage
      }
    }

    if (q && q.translations.currentLang) {
      if (q.translations.currentLang !== languageObj.iso && !isTest()) {
        // TODO: Reload page to fetch the correct language, temp fix
        window.location.reload()
      }
      return
    }

    // TODO: Use CDN (overcome cache issue)
    // const url = `${assetsUrl}i18n/${languageObj.language}.json?v=${version}`

    const url = `./assets/i18n/${languageObj.language}.json?v=${version}`
    const cache: RequestCache = environment === 'local' ? 'no-store' : 'force-cache'
    const res = await betterFetch(url, { cache })
    const translations: QTranslations = await res.json()
    translations.currentLang = languageObj.iso
    q = { translations, assetsUrl }
  }
}
